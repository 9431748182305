import { graphql, Link } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import { Col, Row } from "reactstrap"
import AboutNizza from "../components/content/about-nizza"
import Hero from "../components/layout/hero"
import Layout from "../components/layout/layout"
import ResultSection from "../components/layout/result-section"
import {
  getById,
  getSearchResults,
  getClassAsync,
} from "../components/search/logic"
import SearchResults from "../components/search/search-results"
import SearchSection from "../components/search/search-section"
import Suche from "../components/search/suche"
import SEO from "../components/seo/seo"
import StructuredData from "../components/seo/structured-data"
import Stamp from "../components/content/stamp/stamp"
import {
  getProductTitle,
  getStrippedProductDescription,
} from "../helper/mapper"
import AboutProdukt from "../components/content/about-produkt"
import RecommendationCarousel from "../components/content/carousel-recommendations"
import Divider from "../components/layout/divider"
import _ from 'lodash';

const NizzaProduktTemplate: React.FC<any> = ({
  pageContext,
  data,
  location,
}) => {
  const image = data.fileName.childImageSharp.fluid
  const imageFixed = data.fileName.childImageSharp.fixed

  const [classList, setClassList] = useState([])

  useEffect(() => {
    getClassAsync(pageContext.product.class)
      .then(results => {
        setClassList(results as any)
      })
  }, [pageContext])

  const [searchResult, setSearchResult] = useState([pageContext.product])
  const [searchString, setSearchString] = useState("")

  const handleSearch = _.debounce((newSearchString: string) => {
    if (searchString !== newSearchString) {
      const newSearchResult = getSearchResults(
        // pageContext.data,
        classList,
        newSearchString,
        true
      )
      setSearchString(newSearchString)
      setSearchResult(newSearchResult)
    }
  }, 10)

  const strippedDescription = getStrippedProductDescription(
    pageContext.product.description
  )
  const productTitle = getProductTitle(pageContext.product.description)

  useEffect(() => {
    setSearchString(productTitle)
  }, [pageContext.product])

  const seo = {
    title: `Welche Nizza-Klasse hat${
      productTitle.length < 8 ? "..." : ""
    }? ⇒ ${productTitle}`,
    keywords: [`${productTitle}`],
    description: `Du möchtest eine Marke ${
      productTitle.length < 30 ? `für ${productTitle} ` : ""
    }anmelden? Nutze unsere Suchfunktion und finde die Nizza-Klasse deines Produkts oder deiner Dienstleistung heraus.`,
    location: location,
  }
  const richSnippet = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: {
      "@type": "Question",
      name: `Welche Nizzaklasse hat ${productTitle}?`,
      acceptedAnswer: {
        "@type": "Answer",
        text: `${
          pageContext.product.class < 35
            ? "Das Produkt"
            : "Die Dienstleistungen"
        } ist der Klasse ${
          pageContext.product.class
        } zugeordnet (Nizza-Klassifikation).`,
      },
    },
  }
  return (
    <Layout
      location={
        pageContext.product.class < 35 ? "/produkte" : "/dienstleistungen"
      }
    >
      <SEO {...seo} />
      <StructuredData structuredData={richSnippet}></StructuredData>

      <Hero fluidImage={image} title={productTitle} hasSearchbar={true}>
        <Row className="align-items-center">
          <Col xs="12" md="auto" className="stamp-wrapper">
            <Stamp text={`Nizza-Klasse ${pageContext.product.class}`}></Stamp>
          </Col>
          <Col>
            <p>
              <strong>{strippedDescription}</strong> ist/ sind nach der
              Nizza-Markenklassifizierung der{" "}
              <Link to={`/klasse/${pageContext.product.class}`} title={`Zu ${pageContext.classContent}`}>
                <strong>{`Klasse ${pageContext.product.class}`}</strong>
              </Link>{" "}
              zugeordnet. Diese Klasse beinhaltet u. a. folgende{" "}
              {pageContext.product.class < 35 ? "Produkte" : "Dienstleistungen"}
              :{" "}
              <Link to={`/klasse/${pageContext.product.class}`} title={`Zu ${pageContext.classContent}`}>
                <strong>{pageContext.classContent}</strong>
              </Link>
              .
            </p>
            <p>
              Die internationale Klassifikation kategorisiert Waren und
              Dienstleistungen. Du benötigst diese Klassennummer, um eine Marken
              für ein Produkt oder eine Dienstleistung anzumelden.
            </p>
          </Col>
        </Row>
      </Hero>

      <SearchSection>
        <Suche
          placeholder={`Klasse ${pageContext.product.class} durchsuchen`}
          searchString={searchString}
          handleSearch={handleSearch}
        ></Suche>
      </SearchSection>

      <ResultSection title={`Suchergebnisse (${searchResult.length})`}>
        {SearchResults(searchResult, searchString)}
      </ResultSection>

      <AboutProdukt
        produkt={pageContext.product}
        classContent={pageContext.classContent}
      ></AboutProdukt>
      <RecommendationCarousel
        produkt={pageContext.product}
        recommendations={pageContext.recommendations}
        image={imageFixed}
      ></RecommendationCarousel>
      <Divider fluid={false}></Divider>
      <AboutNizza></AboutNizza>
    </Layout>
  )
}
export default NizzaProduktTemplate

export const query = graphql`
  query CoverImageProduct($image: String) {
    fileName: file(relativePath: { eq: $image }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 980) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 160, height: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

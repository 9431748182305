export function getStrippedProductDescription(description: string) {
    const retVal = description.replace(/\[.*\]|\(.*\)/g, '');
    return (retVal.charAt(0).toUpperCase() + retVal.slice(1)).trim();
}


export function getProductTitle(description: string) {

    const strippedDescription = description.replace(/\[.*\]|\(.*\)/g, '');

    let retVal = strippedDescription;

    const nicht = strippedDescription.indexOf(', nicht')
    if (nicht !== -1) {
        retVal = retVal.substring(0, nicht);
    }
    const oderfuer = strippedDescription.indexOf('oder für')
    if (oderfuer !== -1) {
        retVal = retVal.substring(0, oderfuer);
    }
    const fuer = strippedDescription.indexOf('für')
    if (fuer !== -1) {
        retVal = retVal.substring(0, fuer);
    }

    // const der = strippedDescription.indexOf(', der')
    // if (der !== -1) {
    //     retVal = retVal.substring(0, der);
    // }
    // const die = strippedDescription.indexOf(', die')
    // if (die !== -1) {
    //     retVal = retVal.substring(0, die);
    // }  
    // const das = strippedDescription.indexOf(', das')
    // if (das !== -1) {
    //     retVal = retVal.substring(0, das);
    // }  
    

    const au = retVal.indexOf(', au')
    if (au !== -1) {
        retVal = retVal.substring(0, au);
    }

    return (retVal.charAt(0).toUpperCase() + retVal.slice(1)).trim();
}
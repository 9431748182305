import Img from "gatsby-image"
import React, { useState } from "react"
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Container, Row } from "reactstrap"
import { getProductTitle } from "../../helper/mapper"
import { Link } from "gatsby"

const RecommendationCarousel = ({ produkt, recommendations, image }) => {

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex =
      activeIndex === recommendations.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex =
      activeIndex === 0 ? recommendations.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const type = () => {
    if (recommendations.length > 0 && recommendations[0]) {
      if (recommendations[0].class <= 35) {
        return "Produkte"
      }
      return "Dienstleistungen"
    }
    return ""
  }

  const slides = () => {
    return recommendations.map(item => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item.id}
        >
          <Link to={`/klasse/${item.class}/${item.slug}`}
          title="Zum Produkt">
          <Img
            style={{ borderRadius: "50%" }}
            fixed={image}
            alt={getProductTitle(item.description)}
            title={`Zu ${getProductTitle(item.description)}`}
            backgroundColor={`#040e18`}
          ></Img>

          <h3 className="product-title">
            {getProductTitle(item.description)}
          </h3>
          <div className="product-class">{`Nizza Klasse ${item.class}`}</div>
          </Link>
        </CarouselItem>
      )
    })
  }

  return (
    <Container tag="section">
      <Row>
        <Col xs="12">
          <h2>Ähnlich wie {getProductTitle(produkt.description)}</h2>
        </Col>
        <Col xs="12">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            className="custom-tag"
          >
            <CarouselIndicators
              items={recommendations}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {slides()}
            <CarouselControl
              color="dark"
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              color="dark"
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
        </Col>
      </Row>
    </Container>
  )
}

export default RecommendationCarousel

import React from "react"
import { Spinner } from "reactstrap"

function Suche({ placeholder, searchString, handleSearch, loading }) {

  
  const handleChange = (e) => {
    handleSearch(e.target.value)
  }

  const reset = () => {
    handleSearch("")
  }

  return (
    <div>
      <div className="search-box">
        <span className="icon">
          <i className="fas fa-search" aria-hidden="true"></i>
        </span>
        <input
          value={searchString}
          name="search-input"
          type="text"
          onChange={handleChange}
          placeholder={placeholder ? placeholder : "Mind. 3 Zeichen eingeben"}
          aria-label="Suche"
          autoComplete="off"
        />

        {searchString ? (
          <span
            className="del"
            onClick={() => {
              reset()
            }}
          >
            <i className="fas fa-times" aria-hidden="true"></i>
          </span>
        ) : null}
      </div>
    </div>
  )
}

export default Suche

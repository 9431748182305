import { getHighlightedString } from "./logic"
import React from 'react';

const SearchResults = (searchResult, searchString) => {
  return (
    <ul>
      {searchResult
        ? searchResult.map(item => {
            return (
              <li key={item.description}>{getHighlightedString(searchString, item)}</li>
            )
          })
        : null}
    </ul>
  )
}

export default SearchResults

import { Col, Container, Row } from "reactstrap"
import { useWindowSize } from "../../helper/hooks"
import {
  getProductTitle,
  getStrippedProductDescription,
} from "../../helper/mapper"

const AboutProdukt = ({ produkt, classContent }) => {
  const size = useWindowSize()

  const strippedDescription = getStrippedProductDescription(produkt.description)
  const productTitle = getProductTitle(produkt.description)

  return (
    <Container
      tag="section"
      style={{ marginTop: "30px", marginBottom: "20px" }}
    >
      <Row>
        <Col xs="12">
          <h2>Markenanmeldung für {productTitle}</h2>
        </Col>
        <Col xs="12">
          <p>
            Du spielst mit dem Gedanken, eine eigene Marke in der{" "}
            <strong>Nizza Klasse {produkt.class}</strong> anzumelden? Diese
            Klasse fast u.a. folgende{" "}
            {produkt.class < 35 ? "Produkt" : "Dienstleistungs"}arten zusammen:{" "}
            {classContent}. Auch <strong>{productTitle}</strong> ist/ sind in
            dieser Klasse ({produkt.class}) enthalten.
          </p>
        </Col>
      </Row>
    </Container>
  )
}
export default AboutProdukt

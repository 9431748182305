import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "reactstrap"

const SearchSection: React.FC<any> = ({ children, showGlobalSearchLink }) => {
  return (
    <Container tag="section" className="search">
      <Row className="align-items-center justify-content-center">
        <Col>{children}</Col>
      </Row>
      {showGlobalSearchLink ? (
        <Row>
          <Col
            className="text-center"
            style={{ height: "30px", lineHeight: "30px" }}
          >
            <Link to="/" title="Zur klassenübergreifende Suche">
              <small>
                Alle Klassen durchsuchen 🚀
              </small>
            </Link>
          </Col>
        </Row>
      ) : null}
    </Container>
  )
}

export default SearchSection
